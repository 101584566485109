import loadFrameData from '../action/loadFrameData';

const loadUserDataFromManagementApp = (message) => {
  const { user, client } = message;

  const account = {
    userName: user.name,
    appName: client.name && client.name.trim !== '' ? client.name : 'Your App',
    tenant: user.tenant,
    namespace: client.namespace,
    clientId: client.id,
    clientSecret: client.secret,
    callback: client.callbacks[0] || 'https://YOUR_APP/callback',
  };

  const context = window.context;
  context.executeAction(loadFrameData, { account });
};

const parentOrigin = window.env.DOMAIN_URL_APP;

window.addEventListener('message', (event) => {
  const origin = event.origin || event.originalEvent.origin;
  if (origin !== parentOrigin) return;

  const message = event.data;
  if (message.type === 'userData') {
    loadUserDataFromManagementApp(message);
  }
});
