// We require these here, rather than in the files
// that references them directly because these files are client only
// and can't be included in code that also runs on the server

require('./browser/slate/lib/_dompurify');
require('./browser/slate/lib/_jquery');
require('./vendor/owl.carousel.js');
require('@a0/docs-components/metrics');
require('./browser/slate');
require('./browser/frameListener');
